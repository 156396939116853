import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from '../stores/index';
import { Spinner, ListGroup, Badge, Button, Alert, Row, Col } from 'react-bootstrap';
import { FaCheck, FaClock, FaTimes, FaArrowLeft, FaUser, FaHistory, FaExclamationTriangle, FaArrowUp, FaArrowDown, FaMoneyBillWave, FaExternalLinkAlt, FaInfoCircle, FaDownload, FaUpload, FaRegClock } from 'react-icons/fa';
import { formatDate, formatCurrency, formatPaymentMethod } from '../utils/formatters';
import { getCryptoImageUrl, getPaymentMethodIconUrl } from '../utils/orderUtils';
import '../styles/OrderDetails.css';

const OrderDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { appStore: authStore } = useStores();
  const [order, setOrder] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [i18nData, setI18nData] = useState<any>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setIsLoading(true);
      try {
        if (id) {
          const response = await authStore.fetchOrderDetails(id);
          if (response) {
            setOrder(response);  // Make sure we're setting the data property
          } else {
            setError('Invalid transaction data received');
          }
        } else {
          setError('Order ID is missing');
        }
      } catch (err) {
        setError('Failed to fetch transaction details. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchI18nData = async () => {
      try {
        const response = await fetch('https://kado-asset-haus.s3.amazonaws.com/i18n/en/US.json');
        const data = await response.json();
        setI18nData(data);
      } catch (err) {
        console.error('Error fetching i18n data:');
      }
    };

    fetchOrderDetails();
    fetchI18nData();
  }, [id, authStore]);

  const getStatusIcon = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'completed':
      case 'success':
        return <FaCheck className="text-success" />;
      case 'pending':
        return <FaClock className="text-warning" />;
      case 'failed':
        return <FaTimes className="text-danger" />;
      default:
        return <FaRegClock className="" />;
    }
  };

  const getStatusBadge = (status: string | undefined) => {
    let variant = 'secondary';
    switch (status?.toLowerCase()) {
      case 'pending':
        variant = 'warning';
        break;
      case 'completed':
      case 'success':
      case 'settled':
        variant = 'success';
        break;
      case 'failed':
        variant = 'danger';
        break;
    }
    return (
      <Badge 
        bg={variant} 
        className="text-uppercase" 
        style={{ display: 'inline-block', marginLeft: '5px' }}
      >
        {status}
      </Badge>
    );
  };

  const getErrorMessage = (errorCode: string) => {
    if (!i18nData || !errorCode) return errorCode;
    
    const keys = errorCode.split('.');
    let message = i18nData.messages;
    
    for (const key of keys) {
      if (message && message[key]) {
        message = message[key];
      } else {
        return errorCode; // Return original error code if path is not found
      }
    }
    
    return typeof message === 'string' ? message : errorCode;
  };

  const getShortErrorCode = (errorCode: string) => {
    if (!errorCode) return '';
    const parts = errorCode.split('.');
    return parts.length > 2 ? parts.slice(-2).join('.') : errorCode;
  };

  const renderTxType = (txType: string | undefined) => {
    if (!txType) return 'Unknown';
    
    switch (txType.toLowerCase()) {
      case 'buy':
        return (
          <span className="tx-type buy">
            <FaArrowUp className="mr-1" /> Buy
          </span>
        );
      case 'sell':
        return (
          <span className="tx-type sell">
            <FaArrowDown className="mr-1" /> Sell
          </span>
        );
      default:
        return txType;
    }
  };

  const safelyFormatCurrency = (amount: number | undefined, fallback: string = 'N/A') => {
    return amount !== undefined ? formatCurrency(amount) : fallback;
  };

  if (isLoading) {
    return (
      <div className="order-details-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading transaction details...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  if (!order) {
    return <div>No transaction found</div>;
  }

  return (
    <div className="order-details-container">
      <div className="content-wrapper">
        <Button variant="outline-primary" className="mb-3" onClick={() => navigate('/transactions')}>
          <FaArrowLeft className='inline-svg'/> Back to Orders
        </Button>
        <h1 className="page-title">Transaction: <span className=''>#{order.id}</span></h1>
        
        <Row>
          <Col md={8}>
            <div className="order-section mb-4">
              <h2>Summary</h2>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Type:</strong> {renderTxType(order.type)}</p>
                  <p><strong>Date:</strong> {formatDate(order.createdAt)}</p>
                  <p><strong>Id:</strong> {order.id}</p>
                </div>
                <div className="col-md-6">
                  <p><strong>Status:</strong> {getStatusBadge(order.status)}</p>
                  <p><strong>Asset:</strong> {order.asset}</p>
                  <p><strong>User:</strong> {order.user}</p>
                </div>
              </div>
            
            </div>

            <div className="order-section mb-4">
              <h2>Deposit Details</h2>
              <hr />
              <p><strong>Status:</strong> {getStatusBadge(order.depositStatus)}</p>
              <p>
                <strong>Amount:</strong>
                {order.type === "BUY" ? (
                  <>
                    <img 
                      src={getPaymentMethodIconUrl(order.depositMethod)}
                      alt={formatPaymentMethod(order.depositMethod)}
                      className="payment-icon"
                    />
                    {safelyFormatCurrency(order.depositAmount)} USD
                  </>
                ) : (
                  <>
                    <img 
                      src={getCryptoImageUrl(order.assetSymbol)}
                      alt={order.asset || 'N/A'}
                      className="crypto-icon"
                    />
                    {order.depositAmount} {order.assetSymbol || 'N/A'}
                  </>
                )}
              </p>
              {order.type === "BUY" ? (
                  <>
                   <p><strong>Method:</strong> {formatPaymentMethod(order.depositMethod)}</p>
                  </>
                ) : (
                  <>
                    <p><strong>Method:</strong> {formatPaymentMethod(order.walletAddress)}</p>
                  </>
                )}
            </div>
            
            <div className="order-section mb-4">
              <h2>Disbursement Details</h2>
              <hr />
              <p><strong>Status:</strong> {getStatusBadge(order.disbursementStatus)}</p>
              <p>
                <strong>Amount:</strong>
                {order.type === "SELL" ? (
                  `${safelyFormatCurrency(order.disbursementAmount)} USD`
                ) : (
                  <>
                    <img 
                      src={getCryptoImageUrl(order.assetSymbol)}
                      alt={order.asset || 'N/A'}
                      className="crypto-icon"
                    />
                    {order.disbursementAmount} {order.assetSymbol || 'N/A'}
                  </>
                )}
              </p>
              {order.type === "BUY" ? (
                  <>
                   <p><strong>Destination:</strong> {order.disbursementMethod || 'N/A'}</p>
                  </>
                ) : (
                  <>
                  </>
                )}
              
              {order.txHash && (
                <p>
                  <strong>Transaction Hash:</strong>{' '}
                  <a 
                    href={`${order.blockExplorerURI}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {order.txHash}{' '}
                    <FaExternalLinkAlt size="0.8em" />
                  </a>
                </p>
              )}
              {order.settledAt && <p><strong>Transaction Settled At:</strong> {formatDate(order.settledAt)}</p>}
              {order.memo && <p><strong>Memo:</strong> {order.memo}</p>}
            </div>
          </Col>

          <Col md={4}>
            {order.errorCode && (
              <div className="error-section mb-4">
                <h2>Error</h2>
                <hr />
                <div >
                  <p><strong>Error Code:</strong> <br/>{getShortErrorCode(order.errorCode)}</p>
                  <p><strong>Message displayed to the user:</strong> <br/>{getErrorMessage(order.errorCode)}</p>
                </div>
              </div>
            )}

            <div className="timeline-section mb-4">
              <h2>Timeline</h2>
              <hr />
              <ListGroup variant="flush">
                {order.updates && order.updates.length > 0 ? (
                  order.updates.map((event: any, index: number) => (
                    <ListGroup.Item key={index} className="timeline-item">
                      <div className="timeline-content">
                        <p>{formatDate(event.updatedAt)}</p>
                        {event.message && <p className="timeline-message">{event.message}</p>}
                      </div>
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>No timeline events available</ListGroup.Item>
                )}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderDetails;