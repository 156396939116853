export const getEnvironmentLabel = (endpoint: string): string => {
  switch (endpoint) {
    case 'https://api.kado.money':
      return 'Production';
    case 'https://test-api.kado.money':
      return 'Staging';
    case 'https://dev-api.kado.money':
      return 'Dev';
    case 'http://localhost:8090':
      return 'Local';
    default:
      return `UNKNOWN ${endpoint}`;
  }
};

export const isNonProdEnvironment = (endpoint: string): boolean => {
  return endpoint !== 'https://api.kado.money';
};