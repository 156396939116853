import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/KadoUrlBuilder.scss';

interface Parameter {
  id: string;
  name: string;
  isRequired: boolean;
  defaultValue: string;
  values: string[];
  type: 'input' | 'select' | 'multi';
  options: string;
  description: string;
  userValue?: string | string[];
}

const parameters: Parameter[] = [
  {
    id: 'apiKey',
    name: 'apiKey',
    isRequired: true,
    defaultValue: '',
    values: ['Your Api Key'],
    type:'input',
    options:'string',
    description:'Kado integrator key. This is a public key used for identifier purposes.'
  },
  {
      id: 'lang',
      name: 'Language',
      isRequired: false,
      defaultValue: 'en-us',
      values: ['','en-us', 'es-es', 'pt-br'],
      type:'select',
      options:'string',
      description:'The language that will be selected by default.'
  },
  {
    id: 'onPayCurrency',
    name: 'On Pay Currency',
    isRequired: false,
    defaultValue: 'USD',
    values: ['','USD', 'EUR', 'CAD', 'GBP', 'MXN', 'COP', 'INR', 'CHF', 'AUD', 'ARS', 'BRL', 'CLP', 'JPY', 'KRW', 'PEN', 'PHP', 'SGD', 'TRY', 'UYU', 'TWD', 'VND', 'CRC', 'SEK', 'PLN', 'DKK', 'NOK', 'NZD'],
    type:'select',
    options:'string',
    description:'The fiat currency that will be selected by default for the user to pay in. Users can select any other that is available for selection.'
  },
  {
    id: 'onRevCurrency',
    name: 'On Rev Currency',
    isRequired: false,
    defaultValue: 'USDC',
    values: ['','USDT', 'USDC', 'SOL', 'ETH', 'AVAX', 'DAI', 'OSMO', 'ATOM', 'BTC', 'XLM', 'STARS', 'SCRT', 'INJ', 'CMDX', 'wETH', 'wBTC', 'USDC.e', 'MATIC', 'XRP', 'REGEN', 'WHALE', 'BLD', 'IST', 'XKI', 'XPLA', 'HUAHUA', 'FLIX', 'ARCH', 'LTC', 'NTRN', 'SEI', 'TIA'],
    type:'select',
options:'string',
description:'The cryptocurrency that will be selected by default for the user to buy. Users can select any other that is available for selection.'
  },
  {
    id: 'offPayCurrency',
    name: 'Off Pay Currency',
    isRequired: false,
    defaultValue: 'USDC',
    values: ['','USDT', 'USDC', 'SOL', 'ETH', 'AVAX', 'ATOM', 'BTC', 'XLM', 'XRP', 'LTC', 'NTRN', 'SEI', 'TIA'],
    type:'select',
options:'string',
description:'The cryptocurrency that will be selected by default for the user to sell. Users can select any other that is available for selection.'
  },
  {
    id: 'offRevCurrency',
    name: 'Off Rev Currency',
    isRequired: false,
    defaultValue: 'USD',
    values: ['','USD'],
    type:'select',
options:'string',
description:'The fiat currency that will be selected by default for the user to be paid in. Users can select any other that is available for selection.'
  },
  {
    id: 'product',
    name: 'Product',
    isRequired: false,
    defaultValue: 'All',
    values: ['','BUY', 'SELL', 'SWAP'],
    type:'multi',
options:'Array[string]',
description:'The option that will be selected by default (on-ramp or off-ramp). Users can change the selection.'
  },
  {
    id: 'onPayAmount',
    name: 'On Pay Amount',
    isRequired: false,
    defaultValue: '',
    values: ['Any positive number. Min/max will be validated upon widget load. Stablecoin Min >= 10 Non-Stablecoin Min >= 25'],
    type:'input',
      options:'string',
      description:'The amount that will be selected by default for the user to buy. Users can enter any other amount.'
  },
  {
    id: 'offPayAmount',
    name: 'Off Pay Amount',
    isRequired: false,
    defaultValue: '',
    values: ['Any positive number. Min/max will be validated upon widget load.'],
    type:'input',
options:'string',
description:'The amount that will be entered by default for the user to sell. Users can enter any other amount.'
  },
  {
    id: 'onToAddress',
    name: 'On To Address',
    isRequired: false,
    defaultValue: '',
    values: ['Valid wallet address matching the network'],
    type:'input',
options:'string',
description:'Blockchain address of the wallet where the purchased cryptocurrency will be sent to.'
  },
  {
    id: 'onToAddressMulti',
    name: 'On To Address Multi',
    isRequired: false,
    defaultValue: '',
    values: ['Valid chain:address key-value pairs'],
    type:'input',
options:'string',
description:'Key-value pairs to set multiple wallet addresses, associated to a specific blockchain.'
  },
  {
    id: 'offFromAddress',
    name: 'Off From Address',
    isRequired: false,
    defaultValue: '',
    values: ['Valid wallet address matching the network'],
    type:'input',
options:'string',
description:'Blockchain address of the wallet that cryptocurrency will be sent from, for an off-ramp. '
  },
  {
    id: 'network',
    name: 'Network',
    isRequired: false,
    defaultValue: 'ETHEREUM',
    values: ['','BITCOIN', 'TERRA', 'OSMOSIS', 'SOLANA', 'ETHEREUM', 'JUNO', 'AVALANCHE', 'KUJIRA', 'INJECTIVE', 'ARBITRUM', 'OPTIMISM', 'POLYGON', 'COSMOS HUB', 'STELLAR', 'STARGAZE', 'SECRET', 'BASE', 'COMDEX', 'RIPPLE', 'REGEN', 'AGORIC', 'MIGALOO', 'KI', 'XPLA', 'NOBLE', 'CHIHUAHUA', 'OMNIFLIX', 'ARCHWAY', 'LITECOIN', 'NEUTRON', 'SEI', 'CELESTIA'],
    type:'input',
options:'string',
description:''
  },
  {
    id: 'cryptoList',
    name: 'Crypto List',
    isRequired: false,
    defaultValue: 'All',
    values: ['','USDT', 'USDC', 'SOL', 'ETH', 'AVAX', 'DAI', 'OSMO', 'ATOM', 'BTC', 'XLM', 'STARS', 'SCRT', 'INJ', 'CMDX', 'wETH', 'wBTC', 'USDC.e', 'MATIC', 'XRP', 'REGEN', 'WHALE', 'BLD', 'IST', 'XKI', 'XPLA', 'HUAHUA', 'FLIX', 'ARCH', 'LTC', 'NTRN', 'SEI', 'TIA'],
    type:'multi',
options:'Array[string]',
description:'The crypto network that will be selected by default for buy or sell. Users can select any other networks that are available for selection. '
  },
  {
    id: 'fiatList',
    name: 'Fiat List',
    isRequired: false,
    defaultValue: 'All',
    values: ['','USD', 'CAD', 'GBP', 'EUR', 'MXN', 'COP', 'INR', 'CHF', 'AUD', 'ARS', 'BRL', 'CLP', 'JPY', 'KRW', 'PEN', 'PHP', 'SGD', 'TRY', 'UYU', 'TWD', 'VND', 'CRC', 'SEK', 'PLN', 'DKK', 'NOK', 'NZD'],
    type:'multi',
options:'Array[string]',
description:'A comma-separated list of crypto currencies that users can buy or sell. Only these will show on the widget.'
    
  },
  {
    id: 'networkList',
    name: 'Network List',
    isRequired: false,
    defaultValue: 'All',
    values: ['','BITCOIN', 'TERRA', 'OSMOSIS', 'SOLANA', 'ETHEREUM', 'JUNO', 'AVALANCHE', 'KUJIRA', 'INJECTIVE', 'ARBITRUM', 'OPTIMISM', 'POLYGON', 'COSMOS HUB', 'STELLAR', 'STARGAZE', 'SECRET', 'BASE', 'COMDEX', 'RIPPLE', 'REGEN', 'AGORIC', 'MIGALOO', 'KI', 'XPLA', 'NOBLE'],
    type:'multi',
options:'Array[string]',
description:'A comma-separated list of crypto networks that users can buy or sell. Only these will show on the widget. Note: Requires onRevCurrency to be set.'
  },
  {
    id: 'productList',
    name: 'Product List',
    isRequired: false,
    defaultValue: 'All',
    values: ['','BUY', 'SELL', 'SWAP'],
    type:'multi',
options:'Array[string]',
description:'A comma-separated list of products that the user will see on the widget. '
  },
  {
    id: 'theme',
    name: 'Theme',
    isRequired: false,
    defaultValue: 'light',
    values: ['','light', 'dark'],
    type:'select',
options:'string',
description:'Sets the theme of the widget to light or dark. The default theme is light.'
  },
  {
    id: 'email',
    name: 'Email',
    isRequired: false,
    defaultValue: '',
    values: ['valid email address'],
    type:'input',
options:'string',
description:'The user\s email that you\'d like to trigger a login for'
  },
  {
    id: 'mode',
    name: 'Mode',
    isRequired: false,
    defaultValue: 'full',
    values: ['', 'full', 'minimal'],
    type:'select',
options:'string',
description:'The UI mode. Minimal mode will remove the menu and drop users directly into the Order Confirmation page.'
  },
  {
    id: 'fiatMethodList',
    name: 'Fiat Method List',
    isRequired: false,
    defaultValue: 'All',
    values: ['','card', 'ach', 'apple', 'wire', 'sepa', 'debit_only'],
    type:'multi',
options:'Array[string]',
description:'The fiat payment methods to allow the user to pay with, or sell to. '
  },
  {
    id: 'userRef',
    name: 'User Ref',
    isRequired: false,
    defaultValue: '',
    values: ['valid uuid'],
    type:'input',
options:'string',
description:'An optional reference provided by the integrator to link a reference in the integrator\'s system to a Kado user. '
  },
  {
    id: 'phone',
    name: 'Phone',
    isRequired: false,
    defaultValue: '',
    values: ['valid phone number'],
    type:'input',
options:'string',
description:'The user\'s phone number that you\'d like to verify'
  },
];

const KadoUrlBuilder: React.FC = () => {
  const [params, setParams] = useState<Parameter[]>(parameters);
  const [generatedUrl, setGeneratedUrl] = useState<string>('');

  const handleInputChange = (paramId: string, value: string | string[]) => {
    setParams(prevParams =>
      prevParams.map(param =>
        param.id === paramId ? { ...param, userValue: value } : param
      )
    );
  };

  const generateUrl = () => {
    const urlParams = new URLSearchParams();
    params.forEach(param => {
      if (param.userValue && param.userValue !== '') {
        if (Array.isArray(param.userValue)) {
          urlParams.append(param.id, param.userValue.join(','));
        } else {
          urlParams.append(param.id, param.userValue);
        }
      }
    });
    const url = `https://app.kado.money/?${urlParams.toString()}`;
    setGeneratedUrl(url);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedUrl);
    // You might want to show a toast message here
  };

  const openInNewTab = () => {
    window.open(generatedUrl, '_blank');
  };

  return (
    <div className="kado-url-builder">
      <h1>Kado URL Parameter Builder</h1>
      <p className="description">
        Customize URL parameters for integrating with{' '}
        <a href="https://docs.kado.money/integrations/customizing-your-integration/parameters" target="_blank" rel="noopener noreferrer">
          Kado
        </a>
        . This tool is for demonstration purposes. For detailed information, refer to the{' '}
        <a href="https://docs.kado.money/integrations/customizing-your-integration/parameters" target="_blank" rel="noopener noreferrer">
          official documentation
        </a>
        .
      </p>

      <Card className="mb-4">
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Field</th>
                <th>Required</th>
                <th>Type</th>
                <th>Values</th>
                <th>Default</th>
                <th>Customize</th>
              </tr>
            </thead>
            <tbody>
              {params.map(param => (
                <tr key={param.id}>
                  <td>
                    <strong>{param.name}</strong>
                    <br />
                    <small>{param.description}</small>
                  </td>
                  <td>{param.isRequired ? 'Yes' : 'No'}</td>
                  <td>{param.options}</td>
                  <td>{param.values.filter(v => v !== '').join(', ')}</td>
                  <td>{param.defaultValue}</td>
                  <td>
                    {param.type === 'input' && (
                      <Form.Control
                        type="text"
                        onChange={(e) => handleInputChange(param.id, e.target.value)}
                      />
                    )}
                    {param.type === 'select' && (
                      <Form.Select
                        onChange={(e) => handleInputChange(param.id, e.target.value)}
                      >
                        {param.values.map(value => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </Form.Select>
                    )}
                    {param.type === 'multi' && (
                      <Form.Select
                        multiple
                        onChange={(e) => handleInputChange(param.id, Array.from(e.target.selectedOptions, option => option.value))}
                      >
                        {param.values.map(value => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </Form.Select>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Button variant="primary" onClick={generateUrl} className="mb-4">
        Build Custom URL
      </Button>

      {generatedUrl && (
        <Card className="generated-url-section">
          <Card.Body>
            <Card.Title>Custom URL</Card.Title>
            <p className="url-display">
              <a href={generatedUrl} target="_blank" rel="noopener noreferrer">
                {generatedUrl}
              </a>
            </p>
            <Button variant="secondary" onClick={copyToClipboard} className="mr-2">
              <FontAwesomeIcon icon={faCopy} /> Copy to Clipboard
            </Button>
            <Button variant="secondary" onClick={openInNewTab}>
              <FontAwesomeIcon icon={faExternalLinkAlt} /> Open in New Tab
            </Button>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default KadoUrlBuilder;