import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores/index';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt, faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../styles/Settings.scss';
import { IOrganization } from '../services/interfaces/Organization';

const Settings: React.FC = observer(() => {
  const { appStore: authStore } = useStores();
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>('');

  const showPaySettings = process.env.REACT_APP_SHOW_PAY_SETTINGS === 'true';

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await authStore.fetchOrganizations();
      setOrganizations(response);
    } catch (error) {
      showToastMessage('Failed to fetch organizations');
    }
  };

  const handleCopy = (text: string) => {
    showToastMessage(`${text} copied to clipboard.`);
  };

  const showToastMessage = (message: string) => {
    window.dispatchEvent(new CustomEvent('showToast', { detail: message }));
  };

  const handleEdit = (field: string, currentValue: string) => {
    setEditingField(field);
    setEditValue(currentValue);
  };

  const handleSave = async (orgId: string) => {
    if (!editingField) return;

    try {
      const updateData = { [editingField]: editValue };
      await authStore.updateOrganization(orgId, updateData);
      setOrganizations(orgs => orgs.map(org => 
        org._id === orgId 
          ? { ...org, [editingField]: editValue } 
          : org
      ));
      setEditingField(null);
      setEditValue('');
      showToastMessage('Organization details updated successfully.');
    } catch (error) {
      showToastMessage('Failed to update organization details.');
    }
  };

  const handleCancel = () => {
    setEditingField(null);
    setEditValue('');
  };

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    authStore.setFilterState({ orgId: event.target.value });
  };

  const selectedOrg = organizations.find(org => org._id === authStore.filterState.orgId);

  const renderEditableField = (label: string, value: string, field: string) => (
    <div className="info-item">
      <span className="info-label">{label}:</span>
      {editingField === field ? (
        <InputGroup>
          <Form.Control
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
          <Button variant="success" className="save-button" onClick={() => handleSave(selectedOrg!._id)}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button variant="secondary" className="cancel-button" onClick={handleCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroup>
      ) : (
        <>
          <span className="info-value">{value || 'Not set'}</span>
          <Button variant="link" className="edit-button" onClick={() => handleEdit(field, value)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div className="settings-container">
      <div className="content-wrapper">
        <h1 className="page-title">Settings</h1>
        <Row>
          <Col md={8}>
          {organizations.length > 1 && (
          <Form.Group controlId="orgSelect" className="mb-4">
            <Form.Select value={authStore.filterState.orgId} onChange={handleOrgChange}>
              <option value="">Select an organization</option>
              {organizations.map(org => (
                <option key={org._id} value={org._id}>{org.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
            {selectedOrg && (
              <div key={selectedOrg._id}>
                <Card className="mb-4 settings-card">
                  <Card.Body>
                    <Card.Title>Kado Widget</Card.Title>
                    <p className="settings-subtitle text-secondary pb-4">Integrate the widget into your website or application to start processing orders.</p>
                    
                    <div className="info-item">
                      <span className="info-label">Widget ID:</span>
                      <span className="info-value">{selectedOrg.widgetId}</span>
                      <CopyToClipboard text={selectedOrg.widgetId} onCopy={() => handleCopy('Widget ID')}>
                        <Button variant="link" className="copy-button">
                          <FontAwesomeIcon icon={faCopy} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    {renderEditableField('Webhook URL', selectedOrg.webhookUrl, 'webhookUrl')}
                    {renderEditableField('Webhook Secret', selectedOrg.webhookSecret, 'webhookSecret')}
                  </Card.Body>
                </Card>
                <Card className="mb-4 settings-card">
                  <Card.Body>
                    <Card.Title>Kado Hybrid API</Card.Title>
                    <p className="settings-subtitle text-secondary pb-4">Integrate Kado Hybrid API for full control of transaction processing while Kado handles KYC, payments, and digital assets.</p>
                    <div className="info-item">
                      <span className="info-label">API Key:</span>
                      <span className="info-value">{selectedOrg.apiPubKey}</span>
                      <CopyToClipboard text={selectedOrg.apiPubKey} onCopy={() => handleCopy('API Key')}>
                        <Button variant="link" className="copy-button">
                          <FontAwesomeIcon icon={faCopy} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </Card.Body>
                </Card>
                {showPaySettings && (
                <Card className="mb-4 kado-pay-card">
                  <Card.Body>
                    <Card.Title>Kado Pay</Card.Title>
                    <p className="settings-subtitle text-secondary pb-4">Configure your payment settings to manage payouts.</p>
                    {renderEditableField('Friendly Name', selectedOrg.friendlyName, 'friendlyName')}
                    <div className="info-item">
                      <span className="info-label">Kado Pay URL:</span>
                      <span className="info-value">{`https://pay.kado.money/${selectedOrg.friendlyName}`}</span>
                      <CopyToClipboard text={`https://pay.kado.money/${selectedOrg.friendlyName}`} onCopy={() => handleCopy('Kado Pay URL')}>
                        <Button variant="link" className="copy-button">
                          <FontAwesomeIcon icon={faCopy} />
                        </Button>
                      </CopyToClipboard>
                      <Button variant="link" className="copy-button" onClick={() => window.open(`https://pay.kado.money/${selectedOrg.friendlyName}`, '_blank')}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                )}
              </div>
            )}
          </Col>
          <Col md={4}>
            <Card className="mb-4 help-card ">
              <Card.Body>
              <Card.Title>Secure Your Account</Card.Title>
                <p>Ensure your account is protected by updating your password regularly.</p>
                
                <Link to="/change-password" className="btn btn-outline-primary">
                  Change password
                </Link>
                
              </Card.Body>
            </Card>
            <Card className="mb-4 help-card">
              <Card.Body>
                <Card.Title>Help</Card.Title>
                <p>If you need assistance, please refer to the following resources:</p>
                <ul>
                  <li><a href="https://docs.kado.money" target="_blank" rel="noopener noreferrer">Integration Documentation</a></li>
                  <li><a href="https://kado-url-builder.netlify.app/" target="_blank" rel="noopener noreferrer">URL Parameter Builder</a></li>
                  <li><a href="https://faq.kado.money/en/" target="_blank" rel="noopener noreferrer">Kado FAQ</a></li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default Settings;