import { UserAuth, UserAuthApiResponse } from '../services/interfaces/UserAuth';
import { ITransaction } from '../services/interfaces/Transaction';
import { makeAutoObservable, runInAction } from 'mobx';
import config from '../utils/config';
import localStore from '../utils/localStore';
import jwtDecode, { JwtPayload } from '../utils/jwtDecode';
import { IUser } from '../services/interfaces/User';
import { IOrderStats } from '../services/interfaces/OrderStats';
import { IOrganization } from '../services/interfaces/Organization';
import { ApiWrapper } from 'services/lib/ApiWrapper';

interface FilterData {
  assets: Array<{ value: string, label: string }>;
  organizations: Array<{ value: string, label: string }>;
  paymentMethods: Array<{ value: string, label: string }>;
  promotions: Array<{ value: string, label: string }>;
}

interface FilterDataResponse {
  data: {
    assets: Array<{ value: string, label: string }>;
    organizations: Array<{ value: string, label: string }>;
    buy_payment_methods: Array<{ value: string, label: string }>;
    payment_methods: Array<{ value: string, label: string }>;
    promotions: Array<{ value: string, label: string }>;
  };
}

export class AppStore {
  private _user: UserAuth | null = null;
  private _transactions: ITransaction[] = [];
  private _filterData: FilterData | null = null;
  private _organizations: IOrganization[] = [];
  private _currentEndpoint: string;

  filterState = {
    period: 'last30days',
    status: 'ALL',
    orgId: '',
    orderId: '',
    txType: 'ALL',
  };

  constructor(private apiWrapper: ApiWrapper) {
    makeAutoObservable(this);
    this._currentEndpoint = localStore.get('currentEndpoint') || process.env.REACT_APP_API_URL || '';
    this.apiWrapper.setEndpoint(this._currentEndpoint); // Ensure the apiWrapper uses the correct endpoint
    this.checkAuth();

    window.addEventListener('unauthorized', this.handleUnauthorized);
  }

  private checkAuth() {
    const token = localStore.get(config.localStoreKey.jwt);
    if (token) {
      try {
        const userData = jwtDecode<JwtPayload & UserAuth>(token);
        this._user = userData;
      } catch (error) {
        console.error('Failed to decode token:', error);
        this.logout();
      }
    }
  }

  handleUnauthorized = () => {
    this.logout();
    console.log('Session expired. Please log in again.');
  };

  logout = (redirect: boolean = true) => {
    this._user = null;
    localStore.set(config.localStoreKey.jwt, '');
    localStore.set(config.localStoreKey.logInStatus, 'false');
    localStorage.removeItem('user');
    if (redirect) {
      window.location.href = '/signin';
    }
  };

  async login(email: string, password: string): Promise<UserAuthApiResponse> {
    try {
      
      const res = await this.apiWrapper.login({ email, password });
      if (res.success && res.data?.token) {
        localStore.set(config.localStoreKey.jwt, res.data.token);
        const userData = jwtDecode<JwtPayload & UserAuth>(res.data.token);
        runInAction(() => {
          this._user = userData;
        });
        this.saveUser(userData);
        localStore.set(config.localStoreKey.logInStatus, 'true');
        return res;
      }
      return res;
    } catch (error) {
      return {
        success: false,
        message: 'Invalid email or password.',
      };
    }
  }

  private saveUser(userData: UserAuth): void {
    localStorage.setItem('user', JSON.stringify(userData));
  }

  get user() {
    return this._user;
  }

  get isAuthenticated() {
    return !!this._user;
  }


  async fetchStats(query: string = '') {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getStats(query, token);
      return {
        stats: response.data.stats
      };
    } catch (error) {
      console.error('Failed to fetch transactions and stats:', error);
      return { orders: [], stats: null };
    }
  }

  async fetchTransactions(query: string = '') {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        console.log('No authentication token found');
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getOrders(query, token);
      return {
        orders: response.data.orders || [],
        stats: response.data.stats
      };
    } catch (error) {
      console.error('Failed to fetch transactions and stats:', error);
      return { orders: [], stats: null };
    }
  }

  async fetchUsers(query: string = ''): Promise<IUser[]> {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getUsers(query, token);
      return response.data.users || [];
    } catch (error) {
      console.error('Failed to fetch users:', error);
      throw error;
    }
  }

  get transactions() {
    return this._transactions;
  }

  async getFilterData() {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getFilterData(token) as FilterDataResponse;
      const initialVal = [{ value: '', label: "All" }];
      runInAction(() => {
        this._filterData = {
          assets: initialVal.concat(response.data.assets),
          organizations: initialVal.concat(response.data.organizations),
          paymentMethods: initialVal.concat(response.data.buy_payment_methods, response.data.payment_methods),
          promotions: initialVal.concat(response.data.promotions)
        };
      });
      return this._filterData;
    } catch (error) {
      console.error('Failed to fetch filter data:', error);
      return null;
    }
  }

  get filterData() {
    return this._filterData;
  }

  async fetchOrderStats(query: string = ''): Promise<IOrderStats> {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getOrderStats(query, token);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch order stats:', error);
      throw error;
    }
  }

  async fetchOrderDetails(orderId: string): Promise<ITransaction> {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getOrderDetails(orderId, token);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch order details:', error);
      throw error;
    }
  }

  async fetchOrganizations(): Promise<IOrganization[]> {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.getOrganizations(token);
      runInAction(() => {
        this._organizations = response.data.organizations;
      });
      return this._organizations;
    } catch (error) {
      console.error('Failed to fetch organizations:', error);
      return [];
    }
  }

  get organizations() {
    return this._organizations;
  }

  setFilterState(newState: Partial<typeof this.filterState>) {
    this.filterState = { ...this.filterState, ...newState };
  }

  async updateOrganization(organizationId: string, data:any) {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.updateOrganization(organizationId, data, token);
      return response.data;
    } catch (error) {
      console.error('Failed to update organization:', error);
      throw error;
    }
  }

  async changePassword(currentPassword: string, newPassword: string) {
    try {
      const token = localStore.get(config.localStoreKey.jwt);
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await this.apiWrapper.changePassword(currentPassword, newPassword, token);
      return response.data;
    } catch (error) {
      console.error('Failed to change password:', error);
      throw error;
    }
  }

  async completeNewPasswordChallenge(username: string, oldPassword: string, newPassword: string): Promise<UserAuthApiResponse> {
    try {
      const response = await this.apiWrapper.completeNewPasswordChallenge(username, oldPassword, newPassword);
      if (response.success && response.data) {
        localStore.set(config.localStoreKey.jwt, response.data.token);
        const userData = jwtDecode<JwtPayload & UserAuth>(response.data.token);
        runInAction(() => {
          this._user = userData;
        });
        this.saveUser(userData);
        localStore.set(config.localStoreKey.logInStatus, 'true');
      }
      return response;
    } catch (error) {
      return {
        success: false,
        message: `An error occurred while setting the password. Please ensure your new password meets the required guidelines, or your account might already be activated.`,

      };
    }
  }

  setEndpoint(endpoint: string) {
    this._currentEndpoint = endpoint;
    this.apiWrapper.setEndpoint(endpoint);
    localStore.set('currentEndpoint', endpoint); 
  }

  get currentEndpoint() {
    return this._currentEndpoint;
  }

  async signUp(email: string, accountName: string) {
    try {
      const response = await this.apiWrapper.signUp(email, accountName);
      
    } catch (error) {
      throw error;
    }
  }
}